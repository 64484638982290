<template>
  <a-drawer
    title="保证金明细"
    placement="right"
    :width="'80vw'"
    :closable="true"
    :visible="visible"
    @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :rowKey="(record, index) => index"
      >
        <div slot="payStatus" slot-scope="row">
          <span :class="payStatusClassType[row.depositStatus]">{{ computedPayStatusTypeMapText(row.depositStatus) }}</span>
          <div>
            <a-popconfirm
                v-if="depositType === 'honesty-auction'
                && row.depositStatus === 4
                && (row.txnSeqno || row.transactionId)
                && !row.returnTime"
                title="确定退回已扣除诚信保证金"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleReturnHonestyPrice(row.id)"
            >
              <a-button class="mt-20" size="small" type="danger">已扣除诚信保证金退回</a-button>
            </a-popconfirm>
          </div>
        </div>
        <div slot="checkAndModule" slot-scope="row">
          <div :class="row.checkFlag === 0 ? 'color-red' : 'color-green'">{{ row.checkFlag === 0 ? '未核对' : '已核对' }}</div>
          <div :class="row.wxTransferStatus === 0 ? 'color-green' : 'color-red'">{{ row.wxTransferStatus === 0 ? '已迁移' : '未迁移' }}</div>
        </div>
<!--    订单号    -->
        <div slot="itemOrderNoSlot" slot-scope="row">
          <div v-if="row.orderNo" class="cur-pot color-blue" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</div>
        </div>
        <div slot="actionEvent" slot-scope="row">
          <a-popconfirm
              v-if="depositType === 'buyer-auction'
              && [2, 5].includes(row.depositStatus)
              && !row.returnTime
              && row.checkFlag === 0"
              title="确定退回参拍保证金？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleReturnBuyerPrice(row)"
          >
            <a-button size="small" type="primary">退回参拍保证金</a-button>
          </a-popconfirm>
          <div>
            <a-popconfirm
                v-if="depositType === 'honesty-auction'
                && [2, 5].includes(row.depositStatus)
                && !row.returnTime"
                title="确定退回诚信保证金"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleReturnHonestyPrice(row.id)"
            >
              <a-button class="mt-20" size="small" type="danger">退回诚信保证金</a-button>
            </a-popconfirm>
          </div>
          <div>
            <a-button
                v-if="depositType === 'seller-auction'
                && [2].includes(row.depositStatus)
                && !row.returnTime
                && row.checkFlag === 0"
                class="mt-20"
                size="small"
                type="danger"
                @click="handleReturnSellerPrice(row.id)"
            >退回发拍保证金</a-button>
          </div>
        </div>
      </a-table>
<!--   退回保证金   -->
      <ReturnBondPricePopup ref="returnBondPricePopupEl" @returnBondPrice="returnBondPriceSuccess"/>
    </div>
  </a-drawer>
</template>

<script>
import {payStatusClassType, payStatusTypeMapText} from "@/views/cmsPage/dqPlatform/_data"
import ReturnBondPricePopup from "@/views/cmsPage/dqPlatform/components/ReturnBondPricePopup/index.vue"

export default {
  props: ["userDepositId", 'depositType'],
  components: {ReturnBondPricePopup},
  data() {
    return {
      visible: true,
      list: [],
      loading: false,
      payStatusClassType: payStatusClassType,
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          key: "id",
          title: "id",
          dataIndex: "id"
        },
        {
          align: "center",
          key: "time",
          title: "时间",
          width: 120,
          customRender: (text, row, index) => {
            return <span>{row.createTime}</span>;
          },
        },
        {
          align: "center",
          title: "状态",
          scopedSlots: { customRender: "payStatus" },
        },
        {
          align: "center",
          key: "actiont",
          title: "支付模式",
          customRender: (text, row, index) => {
            if (row.payModule === 2) {
              return <span style="color:blue">连连模式</span>;
            } else if (row.payload === 0) {
              return <span style="color:green">微信模式</span>;
            } else {
              return <span style="color:gray">无</span>;
            }
          },
        },
        {
          align: "center",
          key: "actiontt",
          title: "金额",
          customRender: (text, row, index) => {
            return <span style="color:red">{row.depositMoney}</span>;
          },
        },
        {
          align: "center",
          key: "actione",
          title: "交易单号",
          customRender: (text, row, index) => {
            if (row.payModule === 2) {
              return <span style="color:blue">{row.txnSeqno}</span>;
            } else {
              return <span style="color:green">{row.transactionId}</span>;
            }
          },
        },
        {
          align: "center",
          title: "迁移/核对状态",
          width: 200,
          scopedSlots: { customRender: "checkAndModule" },
        },
        {
          align: "center",
          key: "actionw",
          title: "标题",
          customRender: (text, row, index) => {
            return <span style="color:red;width: 200px;">{row.depositBusinessName}</span>;
          },
        },
        {
          align: "center",
          key: "actions",
          title: "说明",
          width: 200,
          customRender: (text, row, index) => {
            return <span style="color:gray">{row.depositDesc}</span>;
          },
        },
        {
          align: "center",
          title: "订单号",
          scopedSlots: { customRender: 'itemOrderNoSlot'}
        },
        {
          align: "center",
          key: "actionm",
          title: "操作",
          scopedSlots: { customRender: "actionEvent" },
        }
      ],
      isReturnStatus: false,
    };
  },
  computed: {
    computedPayStatusTypeMapText() {
      return value => payStatusTypeMapText(value)
    },
  },
  created() {
    this.$set(this.params, "userDepositId", this.userDepositId);
    this.isReturnStatus = false
    this.getList();
  },
  methods: {
    /** 去往订单详情 */
    handleGoToOrderDetail(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 退回保证金 */
    async handleReturnBuyerPrice(data) {
      const res = await this.axios('/dq_admin/userDeposit/returnBuyerDepositById', {
        params: {
          userDepositDetailId: data.id
        }
      })
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.isReturnStatus = true
      await this.getList()
    },
    /** 退回发拍保证金 */
    async handleReturnSellerPrice(id) {
      this.$refs.returnBondPricePopupEl.show(id, 'seller')
    },
    /** 退回诚信保证金 */
    async handleReturnHonestyPrice(id) {
      const res = await this.axios('/dq_admin/userDeposit/returnDeductHonestyDeposit', {
        params: {
          userDepositDetailId: id
        }
      })
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.isReturnStatus = true
      await this.getList()
    },
    /** 退回成功 */
    async returnBondPriceSuccess() {
      this.isReturnStatus = true
      await this.getList()
    },
    seeClick(row) {
      const { pcgsNumber, reviewStatus, goodsType } = row;
      this.onClose();
      let path = "/cms/silver/confirm";
      let query = { code: pcgsNumber };
      if (this.$route.path === path) {
        this.onClose();
        this.$eventBus.$emit("searchCode", pcgsNumber);
      } else {
        const{ href } = this.$router.resolve({path,query});
        window.open(href)
        // this.$router.push({ path, query });
      }
    },
    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/userDeposit/depositDetailList",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    onClose() {
      this.visible = false;
      setTimeout(() => {
        this.$emit("close", this.isReturnStatus);
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>