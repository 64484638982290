<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="params.userName" allowClear size='small' style="width:150px;" placeholder='昵称或userID'></a-input>

        <a-select
            allowClear
            v-model="params.depositType"
            size='small'
            style="width:120px;margin-left:10px;"
            placeholder='保证金类型'
        >
          <a-select-option value="buyer-auction">参拍</a-select-option>
          <a-select-option value="honesty-auction">诚信</a-select-option>
          <a-select-option value="seller-auction">发拍</a-select-option>
        </a-select>
        <a-select
            allowClear
            v-model="params.depositMoneySort"
            size='small'
            style="width:150px;margin-left:10px;"
            placeholder='保证金余额排序'
        >
          <a-select-option :value="1">升序</a-select-option>
          <a-select-option :value="-1">降序</a-select-option>
        </a-select>
        <a-select
            allowClear
            v-model="params.updateTimeSort"
            size='small'
            style="width:150px;margin-left:10px;"
            placeholder='变更时间排序'
        >
          <a-select-option :value="1">升序</a-select-option>
          <a-select-option :value="-1">降序</a-select-option>
        </a-select>
        <a-checkbox class="ml-20" :checked='isWaitHandleStatus' @change="handleWaitHandleStatus">筛选保证金待退回处理</a-checkbox>
        <a-button @click="search" size='small' type="primary" style="margin-left:10px;">搜索</a-button>
      </div>
    </div>

    <div class="table-content" >
      <a-table
          @change="changePage"
          :pagination='pagination'
          :loading='loading'
          :columns="columns"
          :data-source="data"
          :rowKey="(record,n)=>n"
      >

        <div slot="totalOrderOrCancelOrder" slot-scope="row">
          {{ row.paipinOrderCancelCount }}/{{ row.paipinOrderAllCount }}
        </div>
<!--    未迁移待处理    -->
        <div slot="waitHandleStatusSlot" slot-scope="row">
          <span :class="row.waitHandleStatus ? 'color-red' : 'color-green'">{{ row.waitHandleStatus ? '待退回处理' : '' }}</span>
          <div class="color-gray">{{ row.waitHandleTime }}</div>
          <div class="mt-10">
            <a-popconfirm
                title="确定标记为已处理？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleConfirmTag(row)"
            >
              <a-button
                  v-if="row.waitHandleStatus"
                  size="small"
              >已处理</a-button>
            </a-popconfirm>
          </div>
        </div>
        <div slot="setup" slot-scope="row">
          <a-button
              v-if="row.depositType === 'honesty-auction'"
              style='margin-left:5px'
              type='primary'
              @click="handleShowHonestyAuctionLine(row)"
          >积分明细
          </a-button>

          <a-button
              style='margin-top:10px'
              type='primary'
              @click="depositDetailClick(row)"
          >明细
          </a-button>

          <a-button
            style='margin-top: 10px;'
            v-if="row.depositType === 'seller-auction'"
            type='primary'
            @click="handleShowDeductSellerBondPrice(row)"
          >扣发拍金</a-button>
        </div>
      </a-table>
    </div>
    <a-modal centered :width='500' v-model="showModal" @ok='checkForms' @cancel='cancel'>
      <div style="height:100px;overflow-y:auto;">
        <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
          <a-form-model-item label='用户id' prop='userId'>
            <a-input placeholder='请输入用户ID' v-model="forms.userId"></a-input>
          </a-form-model-item>
          <a-form-model-item label='店铺名称' prop='shopName'>
            <a-input placeholder='请输入店铺名称' v-model="forms.shopName"></a-input>
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>
<!-- 保证金明细 -->
    <DepositDetail
        v-if="userDepositId"
        :userDepositId="userDepositId"
        :depositType="depositType"
        @close="tradeClose"
    ></DepositDetail>
<!--  积分明细  -->
    <UserScoreLine ref="userScoreLineEl"/>
<!--  扣除弹窗  -->
    <SellerBondPricePopup
        ref="sellerBondPricePopupEl"
        @deductBondPrice="search"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DepositDetail from "@/views/cmsPage/dqPlatform/components/UserDepositDetail.vue";
import SellerBondPricePopup from "@/views/cmsPage/dqPlatform/components/SellerBondPricePopup"
import UserScoreLine from "@/views/cmsPage/dqPlatform/components/UserScoreLine/index.vue"
export default {
  data(){
    return {
      isWaitHandleStatus: false,
      columns:[
        {title: "ID",dataIndex: "id"},
        {title: "用户ID",dataIndex: "userId"},
        {title: "用户昵称",dataIndex: "userName"},
        {
          title: "保证金类型",
          dataIndex: "depositType",
          customRender: (text) => {
            const inner = text;
            if (inner == 'buyer-auction') {
              return <span style="color:green">参拍保证金</span>;
            }
            if (inner == 'seller-auction') {
              return <span style="color:red">发拍保证金</span>;
            }
            if (inner == 'honesty-auction') {
              return <span style="color:blue">诚信保证金</span>;
            }
          },
        },
        {title: "保证金金额",dataIndex: "depositMoney"},
        {title: "中拍单[取消/总数]",align: "center",scopedSlots: { customRender: "totalOrderOrCancelOrder" },},
        {title: "待退回处理/申请时间",align: "center",scopedSlots: { customRender: "waitHandleStatusSlot" }, width: 250},
        {title: "变更时间",align: "center", dataIndex: 'updateTime'},
        {title:'创建时间',align:'center',dataIndex:'createTime'},
        {title: "操作",align: "center",scopedSlots: { customRender: "setup" },},
      ],
      data:[],
      forms:{
        userId:'',
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules:{
        userId:[{ required: true, message: '用户ID', trigger: 'blur' }],
      },
      showModal:false,
      visible:false,
      params:{
        pageNum:1,
        pageSize:10,
        userName:'',
        // depositType:'',
        depositMoneySort: undefined,
        updateTimeSort: undefined
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      clicked: false,
      hovered: false,
      userDepositId: '',
      depositType: ''
    }
  },

  computed:{
    ...mapState(['ip'])
  },
  created(){
    this.getData(this.params)
  },
  components: {
    DepositDetail,SellerBondPricePopup, UserScoreLine
  },
  methods:{
    /** 积分明细 */
    handleShowHonestyAuctionLine(row) {
      this.$refs.userScoreLineEl.show(row)
    },
    /** 确定已处理 */
    async handleConfirmTag(row) {
      this.$loading.show()
      const res = await this.axios('/dq_admin/userDeposit/handleStatusDone',{
        params: {id: row.id}
      })
      this.$loading.hide()
      if (res.status !== '200') return
      await this.getData(this.params)
    },
    /** 筛选未迁移待确认 */
    async handleWaitHandleStatus(e) {
      console.log(e, '怕')
      if (!this.params.depositType && !this.isWaitHandleStatus) {
        return this.$message.warn('选择前请先选择一种保证金类型')
      }
      this.isWaitHandleStatus = e.target.checked
      if (this.isWaitHandleStatus) {
        this.params.waitHandleStatus = 1
      } else {
        this.params.waitHandleStatus = null
      }
      await this.getData(this.params)
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/userDeposit/list',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    search(){
      this.params.pageNum = 1
      this.getData(this.params)
    },

    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.params.pageNum = current
      this.params.pageSize = pageSize
      this.getData(this.params)
    },
    openModal(row) {
      this.showModal = true;
    },
    cancel(){
      this.forms = {rankIcon:'',userId:''}
      this.$refs.ruleForm.resetFields();
    },
    checkForms(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    async submit(){
      const res = await this.axios.post('/dq_admin/userShopInfo/add',this.forms)
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
        this.showModal = false
        this.cancel()
      }
    },
    activeShop(row) {
      const id = row.id
      this.axios("/dq_admin/userShopInfo/activeShop", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },

    handleClickChange(visible) {
      this.clicked = visible;
      this.hovered = false;
    },
    tradeClose(status) {
      this.userDepositId = "";
      if (status) {
        this.getData(this.params)
      }
    },
    depositDetailClick(row) {
      this.depositType = row.depositType
      this.userDepositId = row.id;
    },
    /** 显示扣除发拍保证金弹窗 */
    handleShowDeductSellerBondPrice(row) {
      this.$refs.sellerBondPricePopupEl.show(row.id)
    },
    deductSellerDeposit(row){
      const id = row.id
      this.axios("/dq_admin/userDeposit/deductSellerDeposit", {
        params: { userDepositId: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },
    returnSellerDeposit(row){
      const id = row.id
      this.axios("/dq_admin/userDeposit/returnSellerDeposit", {
        params: { userDepositId: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },

  }
}

function d(a,b){
    // setTimeout((
        
    // ),1000);
    // this.params.fansSort = 0
    this.getData(this.params)
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
</style>