<template>
  <a-modal
      v-model="showPopup"
      title="扣除保证金"
      :width="800"
  >
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >

        <a-form-item label="是否扣除保证金">
          <a-radio-group v-model="params.isDeductAll">
            <a-radio-button :value="0">
              扣除部分保证金
            </a-radio-button>
            <a-radio-button :value="1">
              扣除全部保证金
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="扣除金额" v-if="params.isDeductAll === 0">
          <a-input
              allowClear
              v-model="params.deductMoney"
              class="w-200"
              type="number"
              placeholder='扣除金额'
          ></a-input>
        </a-form-item>
        <a-form-model-item label="扣除描述">
          <a-textarea
              v-model="params.deductDesc"
              placeholder="扣除描述"
              :rows="4"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      showPopup: false,
      bondPriceId: '',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        deductDesc: undefined,
        deductMoney: undefined,
        isDeductAll: 0
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.bondPriceId = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        deductDesc: undefined,
        deductMoney: undefined,
        isDeductAll: 1
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (this.params.isDeductAll === 0 && Number(this.params.deductMoney) <= 0) {
        return this.$message.warn('部分扣除保证金，金额不能为空')
      }
      this.params.deductMoney = Number(this.params.deductMoney)
      this.$loading.show()
      const res = await this.axios("/dq_admin/userDeposit/deductSellerDeposit", {
        params: {
          userDepositId: this.bondPriceId,
          ...this.params
        }
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('deductBondPrice')
      this.showPopup = false
    }
  }
}
</script>