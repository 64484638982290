var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "积分明细",
        placement: "right",
        width: "80vw",
        closable: true,
        visible: _vm.visible
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "80vh", "overflow-y": "scroll" } },
        [
          _c("div", { staticClass: "w-100-w flex-start-center" }, [
            _c("div", { staticClass: "color-black" }, [
              _vm._v("用户等级："),
              _c(
                "span",
                { staticClass: "font-weight-bold font-size-24 color-orange" },
                [_vm._v("V" + _vm._s(_vm.baseInfo.userLevel))]
              )
            ]),
            _c("div", { staticClass: "color-black ml-20" }, [
              _vm._v("领先个数："),
              _c(
                "span",
                { staticClass: "font-weight-bold font-size-24 color-red" },
                [_vm._v(_vm._s(_vm.baseInfo.precedeCount))]
              )
            ]),
            _c("div", { staticClass: "color-black ml-20" }, [
              _vm._v(" 用户积分信息： "),
              _vm.baseInfo.userCredit
                ? _c(
                    "span",
                    { staticClass: "font-weight-bold font-size-24 color-blue" },
                    [_vm._v(_vm._s(_vm.baseInfo.userCredit.creditScore))]
                  )
                : _c(
                    "span",
                    { staticClass: "font-weight-bold font-size-24 color-gary" },
                    [_vm._v("暂无")]
                  )
            ])
          ]),
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.list,
              rowKey: function(record, index) {
                return index
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "orderDetailSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#2f54eb", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.handleGoToOrderDetail(row.orderNo)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.orderNo))]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }