<template>
  <a-modal
      v-model="showPopup"
      title="退回保证金"
      :width="700"
  >
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="是否退回保证金">
          <a-radio-group v-model="isReturnAll">
            <a-radio-button :value="0">
              退回部分保证金
            </a-radio-button>
            <a-radio-button :value="1">
              退回全部保证金
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="退回金额" v-if="isReturnAll === 0">
          <a-input
              allowClear
              v-model="params.partReturnMoney"
              class="w-200"
              type="number"
              placeholder='退回金额'
          ></a-input>
        </a-form-item>
        <a-form-model-item label="退回描述" v-if="false">
          <a-textarea
              v-model="params.returnDesc"
              placeholder="退回描述"
              :rows="4"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      showPopup: false,
      bondPriceId: '',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        partReturnMoney: undefined,
      },
      bondPriceTypeMapUrl: {
        buyer: '/dq_admin/userDeposit/returnBuyerDepositById',
        honesty: '/dq_admin/userDeposit/returnDeductHonestyDeposit',
        seller: '/dq_admin/userDeposit/returnSellerDepositDetail'
      },
      bondPriceType: 'seller',
      isReturnAll: 1,
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data, type) {
      this.bondPriceType = type || 'seller'
      this.bondPriceId = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        partReturnMoney: undefined,
      }
      this.isReturnAll = 1
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (this.isReturnAll === 0 && !this.params.partReturnMoney) {
        return this.$message.warn('部分退回保证金，金额不能为空')
      }
      if (this.isReturnAll) {
        this.params.partReturnMoney = undefined
      }
      this.$loading.show()
      const res = await this.axios(this.bondPriceTypeMapUrl[this.bondPriceType], {
        params: {
          userDepositDetailId: this.bondPriceId,
          ...this.params
        }
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('returnBondPrice')
      this.showPopup = false
    }
  }
}
</script>