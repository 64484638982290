<template>
  <a-drawer
      title="积分明细"
      placement="right"
      :width="'80vw'"
      :closable="true"
      :visible="visible"
      @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <div class="w-100-w flex-start-center">
        <div class="color-black">用户等级：<span class="font-weight-bold font-size-24 color-orange">V{{ baseInfo.userLevel }}</span></div>
        <div class="color-black ml-20">领先个数：<span class="font-weight-bold font-size-24 color-red">{{ baseInfo.precedeCount }}</span></div>
        <div class="color-black ml-20">
          用户积分信息：
          <span class="font-weight-bold font-size-24 color-blue" v-if="baseInfo.userCredit">{{ baseInfo.userCredit.creditScore }}</span>
          <span class="font-weight-bold font-size-24 color-gary" v-else>暂无</span>
        </div>
      </div>
      <a-table
          @change="changePage"
          :pagination="pagination"
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :rowKey="(record, index) => index"
      >
        <div slot="orderDetailSlot" slot-scope="row">
          <span style="color: #2f54eb;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
        </div>
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
import {getScoreLineInfo, getUserScoreInfo} from "@/views/cmsPage/dqPlatform/_apis"

export default {
  data() {
    return {
      visible: false,
      list: [],
      loading: false,
      userId: "",
      params: {
        userId: '',
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          key: "id",
          title: "id",
          dataIndex: "id"
        },
        {
          align: "center",
          key: "userId",
          title: "userId",
          dataIndex: "userId"
        },
        {
          align: "center",
          key: "creditType",
          title: "类型",
          dataIndex: "creditType"
        },
        {
          align: "center",
          key: "creditScore",
          title: "分数",
          dataIndex: "creditScore"
        },
        {
          align: "center",
          title: "类型描述",
          dataIndex: "creditDesc",
        },
        {
          align: "center",
          title: "订单号",
          scopedSlots: { customRender: 'orderDetailSlot'}
        },
        {
          align: "center",
          key: "actionm",
          title: "时间",
          dataIndex: "createTime"
        }
      ],
      baseInfo: ""
    };
  },
  computed: {
  },
  methods: {
    async show(data) {
      this.userId = ''
      this.params.userId = ''
      this.userId = data.userId
      this.params.userId = data.userId
      await this.getBaseInfo()
      await this.getScoreLineInfo()
    },
    /** 获取积分相关数据 */
    async getBaseInfo() {
      this.$loading.show()
      const res = await getUserScoreInfo({userId: this.userId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.baseInfo = res.data
      this.visible = true
    },
    async getScoreLineInfo() {
      this.loading = true;
      const res = await getScoreLineInfo(this.params)
      if (res.status !== '200') return
      this.loading = false;
      if (res.status !== '200') return (this.list = []);
      this.list = res.data.records
      this.pagination.total = res.data.total;
    },
    /** 去往订单详情 */
    handleGoToOrderDetail(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    // 切换分页
    changePage(page) {
      const { current } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.getScoreLineInfo()
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>